<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <div class="w-full flex flex-col gap-3 ml-3">
      <div class="flex w-full gap-2">
        <card class="p-3 w-full">
          <div class="flex mb-1">
            <icon icon-name="calendar" class="text-romanSilver" size="xsm" />
            <h6 class="text-blueCrayola text-xs ml-2 mt-1">Employment Date</h6>
          </div>
          <p class="ml-7 font-black text-xs">
            {{
              `${
                employmentDetails.resumptionDate
                  ? $DATEFORMAT(
                      new Date(employmentDetails.resumptionDate),
                      "MMMM dd, yyyy"
                    )
                  : "-"
              }`
            }}
          </p>
        </card>
        <card class="p-3 w-full">
          <div class="flex mb-1">
            <icon icon-name="calendar" class="text-romanSilver" size="xsm" />
            <h6 class="text-carrotOrange text-xs ml-2 mt-1">
              Probation Period
            </h6>
          </div>
          <p class="ml-7 font-black text-xs uppercase">
            {{ `${settings.probationPeriod} months` }}
          </p>
        </card>
        <card class="p-3 w-full">
          <div class="flex mb-1">
            <icon icon-name="calendar" class="text-romanSilver" size="xsm" />
            <h6 class="text-desire text-xs ml-2 mt-1">Confirmation Due Date</h6>
          </div>
          <p v-if="!employmentDetails.confirmationDueDate">-</p>
          <p v-else class="ml-7 font-black text-xs uppercase">
            {{
              $DATEFORMAT(
                new Date(employmentDetails.confirmationDueDate),
                "MMMM dd, yyyy"
              )
            }}
          </p>
        </card>
        <card class="p-3 w-full">
          <div class="flex mb-1">
            <icon icon-name="icon-grid" class="text-romanSilver" size="xsm" />
            <h6 class="text-mediumSeaGreen capitalize text-xs ml-2 mt-1">
              last performance score
            </h6>
          </div>
          <p class="ml-7 font-black text-xs">
            {{
              employmentDetails.employeePerformanceRatingScore ||
              "Appraisal data Unavailable"
            }}
          </p>
        </card>
      </div>

      <div class="mt-2">
        <div class="flex">
          <div class="flex flex-grow">
            <p class="mt-3 text-sm">Performance Ratings</p>
            <div class="cursor-pointer">
              <Icon
                v-tooltip.top-start="
                  `Set the lowest and highest ratings for each performance criteria,
                with ${lowestNumber} being the lowest and ${highestNumber} being the highest.`
                "
                class-name="text-darkPurple h-3 w-1 mt-4 flex cursor-pointer"
                icon-name="icon-info"
                size="xsm"
              />
            </div>
          </div>
          <div class="flex justify-end mt-2 text-flame font-semibold">
            <p class="text-base">
              Overall Score: {{ ` ${Math.ceil(total)}%` }}
            </p>
          </div>
        </div>
        <div
          class="grid grid-cols-12 gap-4 my-3 text-darkPurple"
          style="margin-top: 1.125rem"
        >
          <div
            v-for="(perf, index) in performance"
            :key="index"
            class="col-span-4 grid gap-x-5 gap-y-8 grid-cols-12"
          >
            <h5 class="my-auto font-semibold text-sm mr-2 w-full col-span-6">
              {{ perf.criteria }}
            </h5>
            <c-text
              placeholder="--"
              variant="h-10"
              class="col-span-4 label"
              @input="handleChange($event, perf)"
              :min="lowestNumber"
              :max="highestNumber"
              type="number"
              :rules="['required']"
              :label="perf.criteria"
              v-model="perf.weight"
            />
          </div>
        </div>
      </div>

      <div class="mt-3">
        <div class="flex justify-between">
          <h5 class="mt-3 text-sm">Assessment Recommendation</h5>
          <span class="flex">
            <Icon
              class-name="h-3 w-1 mt-4 flex text-desire"
              icon-name="icon-info"
              size="xsm"
            />
            <h5 class="mt-3 text-sm text-desire">
              Employee qualifies to be confirmed
            </h5>
          </span>
        </div>
        <c-select
          placeholder="--Select--"
          variant="w-full h-10"
          v-model="recommendation"
          :options="[
            { name: 'Do not confirm', id: 'do not confirm' },
            { name: 'Confirm', id: 'confirm' },
            { name: 'Extend Probation', id: 'extend Probation' },
          ]"
          :rules="['required']"
          label="Assessment Recommendation"
          class="h-10 mr-2 label"
        />
      </div>

        <div
          class="flex justify-start items-center gap-3 mt-3"
          style="width: 32%"
          v-if="recommendation === 'extend Probation'"
        >
          <c-select
            class="w-24"
            placeholder="--Select--"
            label="Extend By"
            :options="numbers"
            v-model="extendBy"
          />
          <p class="font-semibold text-base pt-6">months</p>
        </div>

      <div class="w-full mt-5 mb-2 border" />

      <div class="w-full flex flex-col gap-3">
        <h5 class="mt-3 text-sm">Additional Comments</h5>
        <span>
          <CTextarea
            placeholder="--Start typing--"
            class="mt-2"
            value=""
            :row="20"
            :height="100"
            :col="30"
            :input="null"
            v-model="summary"
          />
        </span>
      </div>

      <div class="w-full flex mt-2">
        <div class="flex gap-3">
          <div class="flex flex-grow">
            <Button
              width="7.875rem"
              :class="`text-white buttons`"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="handleSubmit(submitConfirmation)"
            >
              Submit
            </Button>
            <Button width="7.875rem" class="buttons"> Cancel </Button>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
  name: "ConfirmationForm",
  components: {
    ValidationObserver,
    Card,
    CText,
    CSelect,
    CTextarea,
    Button,
  },
  data() {
    return {
      recommendation: null,
      performance: [],
      summary: "",
      extendBy: this.settings.probationPeriod,
      total: 0,
      highestNumber: 0,
      lowestNumber: 0,
      numbers: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
        { id: 6, name: 6 },
        { id: 7, name: 7 },
        { id: 8, name: 8 },
        { id: 9, name: 9 },
        { id: 10, name: 10 },
        { id: 11, name: 11 },
        { id: 12, name: 12 },
      ],
    };
  },
  props: {
    employmentDetails: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    populateData() {
      if (this.employmentDetails.employeeConfirmation) {
        const { recommendation, performanceRating, assesementSummary } =
          this.employmentDetails.employeeConfirmation;
        this.recommendation = recommendation;
        this.addSettings(performanceRating);
        this.summary = assesementSummary;
      } else {
        this.addSettings([]);
      }
    },

    submitConfirmation() {
      const payload = {
        confirmUserId: this.employmentDetails.userId,
        orgId: this.$orgId,
        extendBy: this.extendBy,
        assesementSummary: this.summary,
        recommendation: this.recommendation?.toLowerCase(),
        effectiveDate: this.$DATEFORMAT(new Date(), "yyyy/MM/dd"),
        confirmationDueDate: this.employmentDetails.confirmationDueDate,
        performanceRatingScore: this.total || 0,
        performanceRating: this.performance.map((item) => ({
          criteria: item.criteria,
          weight: Number(item.weight),
        })),
      };
      if (this.employmentDetails.employeeConfirmation) {
        payload.id = this.employmentDetails.employeeConfirmation.id;
      }

      this.$_saveEmployeeConfirmationAssessment(payload)
        .then(() => {
          this.$toasted.success(
            "Employee confirmation request successfully sent for approval",
            { duration: 5000 }
          );
          this.$router.back();
        })
        .catch(() => {
          this.$toasted.error(
            "Employee could not be confirmed please contact your admin",
            { duration: 5000 }
          );
        });
    },

    addSettings(performanceRating) {
      const rating = [];
      this.settings.ratings.forEach((element) => {
        rating.push(element.rating);
      });
      this.highestNumber = Math.max(...rating).toString();
      this.lowestNumber = Math.min(...rating).toString();
      if (performanceRating && performanceRating.length) {
        performanceRating.forEach((element) => {
          this.performance.push({
            criteria: element.criteria,
            weight: element.weight,
          });
          this.handleChange(element.weight, element);
        });
      } else {
        this.settings.criteria.forEach((element) => {
          this.performance.push({
            criteria: element.criteria,
            weight: "",
            total: 0,
          });
        });
      }
    },

    handleChange(value, pref) {
      if (Number(value) < Number(this.lowestNumber)) {
        const message = "Rating cannot be lower than the lowest rated number";
        this.$toasted.error(message, { duration: 3000 });
      }
      if (Number(value) > Number(this.highestNumber)) {
        const message = "Rating cannot be higher than the highest rated number";
        this.$toasted.error(message, { duration: 3000 });
      }

      this.total = 0;
      const perfermance = this.settings.criteria.find(
        (item) => item.criteria === pref.criteria
      );
      const checkPref = this.performance.find(
        (item) => item.criteria === pref.criteria
      );
      checkPref.total =
        (((perfermance.weight / 100) * value) / Number(this.highestNumber)) *
        100;
      this.performance.forEach((item) => {
        this.total += item.total;
      });
    },
  },
  mounted() {
    this.populateData();
  },
};
</script>

<style>
.label label {
  display: none;
}
</style>
